#ResourcesContainer {
  width: 100%;
  padding: 5px;
}

#ResourcesContainer #ResourcesContent {
  background-color: var(--container-1);
  padding: 5px;
  margin: 5px 0;
  border-radius: 0.5rem;
}

#ResourcesContainer #ResourcesContent .text-container {
  background-color: var(--container-2);
  margin: 10px 0;
  padding: 5px;
  border-radius: 0.5rem;
}

#ResourcesContainer #ResourcesContent .text-container p:last-child {
  margin-bottom: 0px;
}

#ResourcesContainer #ResourcesContent .text-container:first-child {
  margin-top: 0px;
}

#ResourcesContainer #ResourcesContent .text-container:last-child {
  margin-bottom: 0px;
}

#ResourcesContainer #ResourcesContent a {
  color: var(--secondary);
  text-decoration: none;
}