#HomeContainer {
  width: 100%;
  padding: 5px;
}

#HomeContainer #HomeContent {
  background-color: var(--container-1);
  padding: 5px;
  margin: 5px 0;
  border-radius: 0.5rem;
}

#HomeContainer #HomeContent .text-container {
  background-color: var(--container-2);
  margin: 10px 0;
  padding: 5px;
  border-radius: 0.5rem;
}

#HomeContainer #HomeContent .text-container.centered {
  text-align: center;
}

#HomeContainer #HomeContent .text-container p:last-child {
  margin-bottom: 0px;
}

#HomeContainer #HomeContent .text-container:first-child {
  margin-top: 0px;
}

#HomeContainer #HomeContent .text-container:last-child {
  margin-bottom: 0px;
}

#HomeContainer #HomeContent a {
  color: var(--secondary);
  text-decoration: none;
}

#HomeContainer .tool-compatibility-table {
  border-collapse: separate;
}

#HomeContainer .tool-compatibility-table th,td{
  border: 1px solid black;
  padding: 0.25rem;
}

#HomeContainer .tool-compatibility-table th {
  background-color: var(--primary);
  color: var(--on-primary);
}


#HomeContainer .tool-compatibility-table td {
  background-color: var(--primary-light);
  color: var(--on-primary);
}

#HomeContainer .tool-compatibility-table td a {
  color: var(--secondary-light);
}

#HomeContainer .tool-compatibility-table tr:first-child th:first-child {
  border-top-left-radius: 0.5rem;
}

#HomeContainer .tool-compatibility-table tr:first-child th:last-child {
  border-top-right-radius: 0.5rem;
}

#HomeContainer .tool-compatibility-table tr:last-child th:first-child {
  border-bottom-left-radius: 0.5rem;
}

#HomeContainer .tool-compatibility-table tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
}

#HomeContainer h3,h4,h5 {
  color: var(--secondary);
}

#HomeContainer .sub-header {
  font-size: 0.9rem;
  font-style: italic;
  color: black;
}