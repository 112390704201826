#DetectorContainer {
  width: 100%;
  margin: auto;
  padding: 5px;
}

#DetectorContent {
  border: 1px solid var(--container-1);
  background-color: var(--container-1);
  border-radius: 0.5rem;
  padding: 5px;
  margin-top: 5px;
}