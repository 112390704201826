#HeaderNavFull {
  display: block;
  margin: 0 auto;
  align-self: center;
}

@media (max-width :980px) {
  #HeaderNavFull {
      display: none;
  }
}

#HeaderNavFull .nav-link {
  color: var(--primary);
  float:left;
  padding: 0;
  font-weight: 600;
  border-bottom: 3px solid transparent;
}

#HeaderNavFull .nav-link:focus {
  outline: none;
}
#HeaderNavFull .nav-link:hover {
  border-bottom: 3px solid var(--secondary);
}

#HeaderNavFull .nav-item {
  float: left;
}

#HeaderNavFull .nav-item::after {
  content: "|";
  border-bottom: 4px solid transparent;
  color: #eaeaea;
  margin: auto;
  padding: 0 1rem;
}