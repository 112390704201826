#Footer {
  height: 60px;
  width: 100%;
  border-top: 1px solid black;
}

#Footer .footer-copyright .content {
  text-align: center;
  margin: 1rem;
}

#Footer .footer-nav-list {
  list-style: none;
  display: flex;
  font-size: 0.75rem;
}

#Footer .footer-nav-list a {
  color: var(--secondary);
}