#GetResultsContainer {
  font-size: 0.9rem;
  margin-top: 5px;
  background: lightgrey;
  border-radius: 5px;
  padding: 2px;
  position: relative;
}

#GetResultsContainer .container-controls {
  position: absolute;
  right: 5px;
}

#GetResultsContainer.result-pending {
  background-color: var(--container-2);
}

#GetResultsContainer.result-detected {
  color: var(--on-background);
  background-color: var(--error-red);
}

#GetResultsContainer.result-clean {
  background-color: var(--success-green);
}

#GetResultsContainer .loading-message {
  margin-left: 5px;
}

#GetResults .close-container-button {
  border-radius: 5px;
  background-color: var(--background);
}

#GetResults .upload-file-error {
  font-size: 0.9rem;
  background-color: var(--error-red);
  color: var(--on-primary);
  padding: 2px;
  border-radius: 5px;
}

#GetResults .upload-file-error.hidden {
  display: none;
}


#GetResults .results-instructions a{
  color: var(--secondary);
  text-decoration: none;
  font-weight: bold;
}

#GetResults .result-detected .results-instructions a{
  color: white;
  text-decoration: none;
}