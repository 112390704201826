#Instructions {
  font-size: 0.9rem;
}

#InstructionsContainer {
  margin-top: 5px;
  padding: 5px;
  background-color: var(--container-2);
  border-radius: 5px;
}

#InstructionsTitle {
  font-weight: bold;
}


#Instructions a {
  color: var(--secondary);
  text-decoration: none;
}

#Instructions .subtext {
  font-size: 0.85rem;
  margin-top: -0.3rem;
  display: block;
}

#Instructions .success {
  color: var(--success-green);
}

#Instructions .fail {
  color: var(--error-red);
}