#HeaderNavDropdown {
  color: var(--on-background);
  border: none;
  background-color: var(--background);
  box-shadow: 3px 3px 10px 1px #666;
  z-index: 99;
  position: absolute;
  width: 200px;
  top: 50px;
  left: 0px;
  display: inline-block;
  padding: 0;
  -webkit-app-region: no-drag;
}

#HeaderNavDropdown .menu-item {
  margin:0;
  font-size: 1.5rem;
}

#HeaderNavDropdown .dropdown-menu-item {
  color: #666;
  cursor: pointer;
  margin: 0;
  font-size: 1rem;
  height: 2.5rem;
  padding-top: 0.5rem;
  min-width: 198px;
  border-bottom: 1px solid #eceef0;
}

#HeaderNavDropdown .dropdown-menu-item a {
  color: #666;
  text-decoration: none;
  display: inline-block;
  padding-right: 15px;
  width: 100%;
}


#HeaderNavDropdown .dropdown-menu-item:hover,
#HeaderNavDropdown .dropdown-menu-item a:hover  {
  color: #000;
}
