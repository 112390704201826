:root {
  --primary: #191919;
  --primary-light: #3f3f3f;
  --primary-dark: #0f0f0f;
  --secondary: #e81410;
  --secondary-light: #ff6659;
  --secondary-dark: #9a0007;
  --background: #ffffff;
  --container-1: #f3f3f3;
  --container-2: #dbdbdb;
  --container-3: #bebebe;
  --surface: #F5F5F6;
  --on-primary: #ffffff;
  --on-secondary: #ffffff;
  --on-background: #000000;
  --on-surface: #ffffff;
  --success-green: #00cc00;
  --error-red: #d10606;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Open Sans',HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 700px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
