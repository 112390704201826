#HeaderContainer {
  display: flex;
  height: 50px;
  position: relative;
}

#HeaderBrand {
  position: absolute;
  left: 1%;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

#Header .brand-logo {
  height: 45px;
  width: 149px;
}

#DropdownButton {
  margin-top: 1rem;
  margin-left: 0.5rem;
  width: 2rem;
}

#DropdownButton .dropdown-icon{
  font-size: 2rem;
}

@media (max-width :980px) {
  #HeaderBrand {
      position: relative;
      left: 0;
      margin: 0 auto;
      align-items: center;
  }
}

@media (min-width :981px) {
  #DropdownButton {
    display:none;
  }
}