
#UploadFile {
  font-size: 0.9rem;
}

#UploadFileContainer {
  color: var(--on-background);
  background: var(--container-2);
  border-radius: 5px;
  padding: 5px;
}

#UploadFile .acknowledge-section .settings-switch {
  margin-right: 5px;
}

#UploadFile .acknowledge-section .share-tip {
  margin-left: 3px;
}

#UploadFile .loading-message {
  margin-left: 5px;
}

#UploadFile .submit-button {
  border-radius: 5px;
  background-color: var(--background);
}

#UploadFile .acknowledge-section {
  margin-top: 5px;
}

#UploadFile .acknowledgement{
  font-size: 0.9rem;
  display: inline;
}

#UploadFile .acknowledgement ul{
  list-style-type: none;
  margin-bottom: 0;
}

#UploadFile .form-checkbox {
  float: left;
  padding-right:2px;
}

#UploadFileContainer .label {
  font-weight: bold;
}

#UploadFileContainer a {
  color: var(--secondary);
  text-decoration: none;
}

#UploadFileContainer .subtext {
  font-size: 0.85em;
}
